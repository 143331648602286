import type { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import { useContext, useEffect } from "react";
import Banner from "../components/Banner/Banner";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Loader from "../components/Loader/Loader";
import ReserveCarousell from "../components/Reserve-Carousell/ReserveCarousell";
import Services from "../components/Services/Services";
import { AuthContext } from "../contexts/auth-context";
import { SpacesService } from "../services/spaces.service";
import { UserService } from "../services/user.service";
import styles from "../styles/Home.module.scss";

const Home: NextPage = ({ space }: any) => {
  const authContext: any = useContext(AuthContext);
  useEffect(() => {
    authContext.setworking_hours_list(space?.working_hours_list);
    return () => {};
  });

  return (
    <>
      <div className="bg-white">
        <Header />
        <Banner />
        {space.id && <ReserveCarousell space={space} />}
        {space.services && <Services services={space.services} />}
        <Footer />
      </div>
    </>
  );
};

export default Home;

export async function getServerSideProps({ params }: any) {
  const _SpacesService = SpacesService.getInstance();
  const _space = await _SpacesService.get();
  const [space]: any =
    // {}
    Array.isArray(_space) ? _space : [];
  return {
    props: {
      space: space || {},
      // place,
    }, // will be passed to the page component as props
  };
}

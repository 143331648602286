import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";
import dynamic from "next/dynamic";
import { useAnimation, motion, useInView } from "framer-motion";

const Carousel = dynamic(async () => import("../Carousel/Carousel"), {
  ssr: false,
  loading: () => <>Loading</>,
});

export default function ReserveCarousell({ space }: any) {
  const ref: any = useRef();
  const inView = useInView(ref, { once: true, amount: 0.89 });
  const controls = useAnimation();
  useEffect(() => {
    controls.start("visible");
  }, [controls, inView]);
  const _useRouter = useRouter();
  const handleReserve = (spaceId: number, id: number) => {
    _useRouter.push(`${spaceId}/${id}`);
  };
  return (
    <div id="carousell" className="bg-light pt-12 pb-[80px] mt-12">
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={{
          visible: {
            opacity: 1,
            scale: 1,
            transition: { duration: 1 },
            left: "0px",
          },
          hidden: {
            opacity: 0,
            scale: 1,
            left: "81px",
          },
        }}
      >
        <h2 className="text-black text-[30px] font-extrabold text-center">
          Elige el sitio que más te motiva e inspira
        </h2>
        <div className="mx-auto w-[50px] h-[2px] bg-primary mt-3 mb-7"></div>
        <Carousel
          places={space.places}
          id={space.id}
          handleReserve={handleReserve}
        />
      </motion.div>
    </div>
  );
}

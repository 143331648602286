import React from "react";

export default function Whatsapp({
  fill = "#252F38",
  width = "24px",
  height = "24px",
}: any) {
  return (
    <svg
      style={{
        fill,
        width,
        height,
      }}
      id="Icon_awesome-whatsapp"
      data-name="Icon awesome-whatsapp"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Icon_awesome-whatsapp-2"
        data-name="Icon awesome-whatsapp"
        d="M20.405,5.738A11.9,11.9,0,0,0,1.687,20.089L0,26.25l6.305-1.655a11.857,11.857,0,0,0,5.684,1.446h.005A12.007,12.007,0,0,0,24,14.148a11.94,11.94,0,0,0-3.595-8.411Zm-8.411,18.3a9.867,9.867,0,0,1-5.036-1.377L6.6,22.446l-3.739.98,1-3.648L3.621,19.4a9.9,9.9,0,1,1,18.37-5.255,10,10,0,0,1-10,9.889Zm5.421-7.4c-.295-.15-1.757-.868-2.03-.964s-.471-.15-.67.15-.766.964-.943,1.168-.348.225-.643.075a8.089,8.089,0,0,1-4.045-3.536c-.305-.525.305-.487.873-1.623a.551.551,0,0,0-.027-.52c-.075-.15-.67-1.612-.916-2.207-.241-.579-.487-.5-.67-.509s-.37-.011-.568-.011a1.1,1.1,0,0,0-.793.37,3.339,3.339,0,0,0-1.039,2.48,5.822,5.822,0,0,0,1.211,3.075,13.279,13.279,0,0,0,5.079,4.489c1.886.814,2.625.884,3.568.745a3.044,3.044,0,0,0,2-1.414,2.485,2.485,0,0,0,.171-1.414C17.909,16.854,17.711,16.779,17.416,16.634Z"
        transform="translate(0 -2.25)"
      />
    </svg>
  );
}

import Image from "next/image";
import Link from "next/link";
import React, { useContext } from "react";
import Whatsapp from "../Whatsapp/Whatsapp";
import { AuthContext } from "../../contexts/auth-context";

export default function Footer() {
  const { working_hours_list = [] }: any = useContext(AuthContext);
  const year = new Date().getFullYear();
  return (
    <footer className="bg-white">
      <div className="border-t border-b border-light-50 py-12" id="footer">
        <div className="w-[90%] grid grid-cols-1 md:grid-cols-4 gap-8 pt-[30px] mx-auto mb-8">
          <div className="text-center md:text-left">
            <h4 className="text-coal text-[16px] font-bold mb-4">Cetiwork</h4>
            <div className="text-primary hover:underline">
              <Link href="/terminos-y-condiciones" target="_blank">
                Términos y condiciones
              </Link>
            </div>
            <div className="text-primary hover:underline">
              <Link
                href="https://www.darient.com/es/politicas-de-privacidad"
                target="_blank"
              >
                Políticas de privacidad
              </Link>
            </div>
          </div>
          <div className="text-center md:text-left">
            <h4 className="text-coal text-[16px] font-bold mb-4">
              Horario de atención
            </h4>
            {working_hours_list.map((text: string, index: number) => {
              const [title, ...value] = text.split(":");
              const remainder = value.join(":");
              const content = (
                <p key={index} className={`mb-6`}>
                  {title}: <br />
                  {remainder}
                </p>
              );
              return content;
            })}
          </div>
          <div className="text-center md:text-left">
            <h4 className="text-coal text-[16px] font-bold mb-4">
              Contáctanos
            </h4>
            <p className="mb-6">
              Email: <br />
              <Link href="mailto:administracion@darient.com">
                administracion@darient.com
              </Link>
            </p>
            <div className="flex items-center justify-center md:justify-start">
              <div className="mr-2 hidden md:block">
                <Whatsapp />
              </div>
              <div className="">
                <div className="font-normal flex mb-2 md:mb-0">
                  <div className="inline-block md:hidden mr-1">
                    <Whatsapp />
                  </div>
                  <span>Whatapps</span>
                </div>
                <Link
                  target="_blank"
                  className="text-primary cursor-pointer hover:underline"
                  href="https://api.whatsapp.com/send?phone=50769189725&text=Hola%20%F0%9F%91%8B,%20te%20estoy%20contactando%20desde%20tu%20P%C3%A1gina%20Web.%20Mi%20nombre%20es"
                >
                  +507 6918-9725
                </Link>
              </div>
            </div>
          </div>
          <div className="text-center md:text-left">
            <h4 className="text-coal text-[16px] font-bold mb-4">Dirección</h4>
            <p>
              Calle 50, Torre <br />
              Panamá Business Hub,
              <br /> Piso 22, Ciudad de Panamá,
              <br /> Panamá.
              <br />
              <Link
                href="https://goo.gl/maps/eGxLCtwM5h89Jzkh8"
                className="text-primary hover:underline"
                target="_blank"
              >
                Ver en el mapa
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 items-center w-[90%] mx-auto py-5">
        <div className="col-span-1">
          <Link href="/">
            <Image
              src="/images/png/logo.png"
              width={94}
              height={32}
              layout="intrinsic"
              alt="logo"
            ></Image>
          </Link>
        </div>
        <span className="text-black-50 text-[12px] col-span-11 ml-3">
          @Copyright {year} - By Darien Technology S.A.
        </span>
      </div>
    </footer>
  );
}

import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

export default function Header() {
  const _useRouter = useRouter();
  const [pageTitle, setpageTitle] = useState("CETI");
  const [logo, setlogo]: any = useState("");
  useEffect(() => {
    if (_useRouter.isReady) {
      const logo = process.env.NEXT_PUBLIC_LOGO;
      setlogo(logo);
    }
    return () => {};
  }, [_useRouter.isReady]);
  return (
    <div className="bg-white">
      <div className="grid grid-cols-2 py-6 w-[90%] mx-auto">
        <div className="flex justify-start">
          {logo && (
            <Link href="/">
              <Image
                src={logo}
                width={94}
                height={32}
                layout="intrinsic"
                alt="logo"
              ></Image>
            </Link>
          )}
        </div>
        <div className="flex justify-end">
          <a
            href="#footer"
            className="flex items-center bg-primary text-white font-bold px-4 text-[14px] rounded"
          >
            Contáctanos
          </a>
        </div>
      </div>
    </div>
  );
}

import { useAnimation, motion, useInView } from "framer-motion";
import Image from "next/image";
import { useEffect, useRef } from "react";

export default function Banner() {
  const ref: any = useRef();
  const inView = useInView(ref, { once: true, amount: 0 });
  const controls = useAnimation();
  useEffect(() => {
    controls.start("visible");
  }, [controls, inView]);
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 w-[90%] mx-auto pt-8 relative">
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={{
          visible: {
            opacity: 1,
            scale: 1,
            transition: { duration: 1 },
            left: "0",
          },
          hidden: { opacity: 0, scale: 1, left: "81px" },
        }}
      >
        <div>
          <h3 className="text-[16px] text-primary font-bold">
            OPEN WORKSPACES
          </h3>
          <h1 className="text-black font-extrabold text-[48px] leading-[59px] my-6">
            Elige dónde quieres <br />
            <span className="text-primary">crear</span> hoy
          </h1>
          <p className="font-medium">
            Puestos flexibles en un entorno creativo y estimulante con <br />
            muebles especiales de aislamiento acústico que fomentan la <br />
            productividad y concentración.
          </p>
          <button className="bg-primary text-white px-3 font-bold mt-4 py-1 rounded">
            <a href="#carousell">Elige tu sitio</a>
          </button>
          <div className="grid grid-cols-3 mt-4 mb-6 max-w-[480px]">
            <div>
              <h2 className="text-[30px] font-extrabold">24</h2>
              <p className="text-[14px] text-black font-medium">
                Puestos Flexibles
              </p>
            </div>
            <div>
              <h2 className="text-[30px] font-extrabold">3</h2>
              <p className="text-[14px] text-black font-medium">Phone Booth</p>
            </div>
            <div>
              <h2 className="text-[30px] font-extrabold">4</h2>
              <p className="text-[14px] text-black font-medium">
                Zonas de relax
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-3">
              <div className="hidden md:block">
                <Image
                  src="/images/png/map-marker.png"
                  width={20}
                  height={27}
                  layout="intrinsic"
                  alt="map marker"
                ></Image>
              </div>
              <div className="block md:hidden">
                <Image
                  src="/images/png/map-marker.png"
                  width={20}
                  height={27}
                  layout="fixed"
                  alt="map marker"
                ></Image>
              </div>
            </div>
            <div className="inline">
              <p className=" text-coal font-medium">
                Calle 50, Torre Panamá Business Hub, Piso 22,
                <br className="hidden md:block" /> Ciudad de Panamá, Panamá.{" "}
                <span className="text-primary underline">
                  <a
                    href="https://goo.gl/maps/eGxLCtwM5h89Jzkh8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ver en mapa
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </motion.div>
      <div className="grid grid-cols-2 gap-5 mt-12 md:mt-0">
        <div className="w-full flex justify-end flex-wrap">
          {[
            { i: 1, width: 212, height: 156 },
            { i: 3, width: 299, height: 390 },
          ].map((img: any, index: number) => (
            <motion.div
              key={index}
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: {
                  opacity: 1,
                  scale: 1,
                  transition: { duration: 1 + index },
                  left: "0px",
                },
                hidden: { opacity: 0, scale: 1, transform: "81px" },
              }}
            >
              <div className="mb-3">
                <img
                  src={`/images/png/banner/banner-${img.i}.png`}
                  width={img.width}
                  height={img.height}
                  alt={`banner-${img.i}.png`}
                />
              </div>
            </motion.div>
          ))}
        </div>
        <div className="mt-12">
          {[
            { i: 2, width: 238, height: 288 },
            { i: 4, width: 167, height: 144 },
          ].map((img: any, index: number) => (
            <motion.div
              key={index}
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: {
                  opacity: 1,
                  scale: 1,
                  transition: { duration: 1 + index },
                  left: "0px",
                },
                hidden: { opacity: 0, scale: 1, left: "81px" },
              }}
            >
              <div className="mb-3">
                <img
                  src={`/images/png/banner/banner-${img.i}.png`}
                  width={img.width}
                  height={img.height}
                  alt={`banner-${img.i}.png`}
                />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}
